<!-- pictrueTextMsg -->
<template>
  <div class="pictrueTextMsg" v-loading="loading">
    <div class="options">
      <div class="options-item">
        <span>选择年份：</span>
        <el-select
          v-model="chooseYear"
          clearable
          filterable
          placeholder="请选择"
          @change="yearChange"
        >
          <el-option
            v-for="(item, index) in yearList"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
      <div class="options-item">
        <span>选择刊期：</span>
        <el-select
          v-model="chooseIssue"
          clearable
          filterable
          placeholder="请选择"
          @change="issueChange"
        >
          <el-option
            v-for="(item, index) in issueList"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
      <el-button
        :disabled="createIframeBtnDisabled"
        @click="createIframe"
        data-track="增值服务-主动传播-微信传播-图文消息模板-生成图文消息"
        >生成图文消息</el-button
      >
      <div
        class="copyTrackingNumber"
        data-clipboard-target="#pictrueTextContent"
        @click="copyTrackingNumber"
      >
        <el-button
          :disabled="copyIframeBtnDisabled"
          data-track="增值服务-主动传播-微信传播-图文消息模板-一键复制"
          >一键复制</el-button
        >
      </div>
    </div>
    <div class="pictrue-text" v-if="showIframe">
      <div id="pictrueTextContent" class="pictrueTextContent">
        <section>
          <section
            style="
              background-size: 100% 100%;
              height: 146px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-repeat: no-repeat;
              margin: auto;
            "
            :style="{
              'background-color': basicColor
            }"
          >
            <section style="text-align: right; flex: 1">
              <img
                style="
                  width: 74px;
                  height: 106px;
                  background: #f7f7f7;
                  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
                  border-radius: 5px;
                  object-fit: contain;
                "
                :src="magCover"
                alt=""
              />
            </section>
            <section
              style="flex: 2; margin-left: 20px; line-height: 20px; color: #fff"
            >
              <section style="font-size: 18px; font-weight: bold">
                {{ netWorkJournalInfo.magName }}
              </section>
              <section
                v-if="netWorkJournalInfo.language === 'en'"
                style="font-size: 14px; margin-top: 15px; display: inline-block"
              >
                {{ netWorkJournalInfo.year }}-{{ netWorkJournalInfo.issue }}
              </section>
              <section
                v-else
                style="font-size: 14px; margin-top: 15px; display: inline-block"
              >
                {{ netWorkJournalInfo.year }}年 第{{
                  netWorkJournalInfo.issue
                }}期
              </section>
            </section>
          </section>

          <section style="height: 0">
            <img
              :src="headerBgBase64"
              style="width: 100%; margin-top: -200px; margin-bottom: -90px"
            />
          </section>

          <section
            style="
              padding: 30px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin: auto;
            "
          >
            <section
              style="
                width: 35%;
                height: 3px;
                border-top: 1px solid #cccccc;
                border-bottom: 1px solid #cccccc;
              "
            ></section>
            <section
              style="
                font-size: 14px;
                font-weight: bold;
                word-break: keep-all;
                margin: 0 10px;
              "
            >
              {{
                netWorkJournalInfo.language === 'en' ? 'CONTENT' : '当期目次'
              }}
            </section>
            <section
              style="
                width: 35%;
                height: 3px;
                border-top: 1px solid #cccccc;
                border-bottom: 1px solid #cccccc;
              "
            ></section>
          </section>
          <section style="width: 100%; margin: auto">
            <section v-for="(item, index) in articlelist" :key="index">
              <section
                style="
                  font-size: 14px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                "
                :style="{ color: basicColor }"
              >
                <section
                  :style="{ 'border-color': basicColor }"
                  style="
                    display: inline-block;
                    width: 11px;
                    height: 11px;
                    border: 1px solid;
                    margin-right: 10px;
                  "
                ></section>
                <section>{{ item.column }}</section>
              </section>
              <section
                style="display: flex; margin: 20px 0"
                v-for="(it, inx) in item.articles"
                :key="inx"
              >
                <section
                  v-if="it.pictureUrl"
                  style="
                    height: 71px !important;
                    background: #ffffff;
                    border: 1px solid #dbdbdb;
                    object-fit: contain;
                    margin-right: 15px;
                  "
                >
                  <section
                    style="
                      width: 79px;
                      height: 71px !important;
                      display: flex;
                      align-items: center;
                      color: red;
                    "
                  >
                    <img
                      style="
                        width: 100%;
                        height: 100% !important;
                        background: #ffffff;
                        object-fit: contain;
                        max-height: -webkit-fill-available;
                      "
                      :src="it.pictureUrl ? it.coverBase64 : noImg"
                      alt=""
                    />
                  </section>
                </section>
                <a :href="it.link" target="_blank">
                  <section style="display: grid; line-height: 22px">
                    <section
                      style="
                        margin-bottom: 5px;
                        font-size: 14px;
                        color: #333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        word-break: break-word;
                        overflow: hidden;
                      "
                      v-html="it.topic"
                    ></section>
                    <section
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        word-break: break-all;
                        font-size: 12px;
                        color: #999;
                      "
                      v-html="
                        (netWorkJournalInfo.language === 'en'
                          ? 'Authors:'
                          : '作者：') + (it.authors ? it.authors : '--')
                      "
                    ></section>
                    <section
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        word-break: break-all;
                        font-size: 12px;
                        color: #999;
                      "
                      v-html="
                        (netWorkJournalInfo.language === 'en'
                          ? 'Keywords:'
                          : '关键词：') + (it.keywords ? it.keywords : '--')
                      "
                    ></section>
                  </section>
                </a>
              </section>
            </section>
          </section>
          <section style="height: 0; margin-bottom: 0 !important">
            <img
              :src="codeBase64"
              style="width: 100%; height: 205px !important"
            />
          </section>
          <section
            style="
              background-size: 100% 100%;
              width: 100%;
              height: 186px;
              margin: auto;
              overflow: hidden;
            "
          >
            <section style="text-align: center; z-index: 2; position: relative">
              <img
                style="width: 106px; height: 106px; margin-top: 30px"
                :src="codeCover"
                alt=""
              />
              <section
                style="
                  color: #333;
                  font-size: 12px;
                  margin-top: 10px;
                  text-align: center;
                "
              >
                {{ codeInfo.text }}
              </section>
            </section>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import ClipboardJS from 'clipboard'
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.contentAggregation
import { mapState } from 'vuex'
// import axios from 'axios'
/* eslint-disable */
export default {
  name: 'PictrueTextMsg',
  data() {
    return {
      noImg: require('@assets/img/noImg.jpg'),
      codeBg: require('@assets/img/code-bg.png'),
      headerBg: require('@assets/img/cover-bg.png'),
      coverUrl: api.GetPictureStream.url,
      chooseYear: '',
      chooseIssue: '',
      yearList: [],
      issueList: [],
      netWorkJournalInfo: {},
      showIframe: false,
      clipboard: '',
      moduleList: [],
      articlelist: [],
      magCover: '',
      codeCover: '',
      codeBase64: '',
      configInfo: '',
      headerBgBase64: '',
      imageAllLoad: false,
      loading: false
    }
  },
  props: {},
  components: {},
  computed: {
    ...mapState('incrementService', ['magId']),
    createIframeBtnDisabled() {
      return !this.chooseYear || !this.chooseIssue
    },
    copyIframeBtnDisabled() {
      return !(
        !this.createIframeBtnDisabled &&
        this.showIframe &&
        this.imageAllLoad
      )
    },
    coverSrc() {
      return this.netWorkJournalInfo.magCoverId
        ? `${this.coverUrl}?pictureId=${this.netWorkJournalInfo.magCoverId}`
        : this.noImg
    },
    basicColor() {
      return this.configInfo.color ? this.configInfo.color : '#00638B'
    },
    codeInfo() {
      let info = {
        url: '',
        text: ''
      }
      if (this.moduleList.length > 0) {
        this.moduleList.map((item) => {
          if (item.modlueIndex === 7) {
            info.url = item.wechatImageUrl
            info.text = item.wechatCoverName
          }
        })
      }
      return info
    }
  },
  watch: {
    magId() {
      this.getJournalYear()
      this.getConfig()
    }
  },
  mounted() {
    if (this.magId) {
      this.getJournalYear()
      this.getConfig()
    }
    this.getBase64Image(this.codeBg).then((res) => {
      this.codeBase64 = res.base64
    })
    this.getBase64Image(this.headerBg).then((res) => {
      this.headerBgBase64 = res.base64
    })
  },
  methods: {
    getMagCover(id, type) {
      let url = '/das-api/vas/push/configManage/getPictureBase'
      let params = {
        pictureId: id
      }
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let src = 'data:image/jpg;base64,' + data.data
          if (type === 'cover') {
            this.magCover = src
          } else if (type === 'code') {
            this.codeCover = src
          }
        }
      })
    },
    // 获取年列表
    getJournalYear() {
      console.log(this.magId)
      const params = {
        magId: this.magId
      }
      let url = '/das-api/vas/networkjournal/journalYear'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data) {
            this.yearList = data
            if (data.length > 0) {
              this.chooseYear = data[0]
              this.getJournalIssue()
            }
          }
        }
      })
    },
    getJournalIssue() {
      const params = {
        magId: this.magId,
        year: this.chooseYear
      }
      let url = '/das-api/vas/networkjournal/journalIssue'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data) {
            this.issueList = data
            if (data.length > 0) {
              this.chooseIssue = data[0]
              this.getNetworkJournalInfo()
            }
          }
        }
      })
    },
    getNetworkJournalInfo() {
      this.loading = true
      const params = {
        magId: this.magId,
        year: this.chooseYear,
        issue: this.chooseIssue
      }
      let url = '/das-api/vas/networkjournal/journal'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          if (res.data.data) {
            this.netWorkJournalInfo = res.data.data
            this.getTaskArticleList()
            this.getMagCover(this.netWorkJournalInfo.magCoverId, 'cover')
            this.getMagCover(this.configInfo.wechatCoverId, 'code')
          }
        }
      })
    },
    getConfig() {
      const params = {
        magId: this.magId
      }
      let url = '/das-api/vas/networkjournal/getConfig'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          if (res.data.data) {
            this.configInfo = res.data.data
          }
        }
      })
    },
    getTaskArticleList() {
      const params = {
        networkId: this.netWorkJournalInfo.id,
        nameSpace: 'www.das.publish.founderss.cn/das-api'
      }
      let url = '/das-api/getNetworkJournalData'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data) {
            this.moduleList = data
            this.articlelist = []
            this.showIframe = true
            this.loading = false
            if (data && data.length > 0) {
              data.map((item) => {
                if (item.modlueIndex === 3) {
                  this.articlelist.push(item.articleMap)
                }
              })
              if (this.articlelist.length > 0) {
                let result = []
                this.articlelist.map((item) => {
                  if (item.articles.length > 0) {
                    item.articles.map((it) => {
                      if (it.recodeId) {
                        result.push(this.getPictureBase(it.recodeId, it))
                      }
                    })
                  }
                })
                Promise.all(result).then(() => {
                  this.imageAllLoad = true
                })
              }
            }
          }
        }
      })
    },
    getPictureBase(id, item) {
      const promise = new Promise((resolve) => {
        let url = '/das-api/vas/push/configManage/getPictureBase'
        let params = {
          pictureId: id
        }
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.$set(item, 'coverBase64', 'data:image/jpg;base64,' + data.data)
            resolve()
          }
        })
      })
      return promise
    },
    yearChange() {
      // this.chooseIssue = ''
      this.getJournalIssue()
    },
    issueChange() {
      this.getNetworkJournalInfo()
    },
    createIframe() {
      this.imageAllLoad = false
      this.getNetworkJournalInfo()
    },
    copyTrackingNumber() {
      if (this.copyIframeBtnDisabled) {
        return
      }
      if (this.clipboard) {
        this.clipboard.destroy()
      }
      // 括号中的内容：对应复制按钮的class
      this.clipboard = new ClipboardJS('.copyTrackingNumber')
      this.clipboard.on('success', (e) => {
        this.$message.success('已复制，去微信公众号后台粘贴吧~')
        e.clearSelection()
        // 释放内存
        this.clipboard.destroy()
      })
      this.clipboard.on('error', (e) => {
        this.$message.error('复制失败，请重试')
        console.log(e)
        // 释放内存
        this.clipboard.destroy()
      })
    },
    getBase64Image(url) {
      const img = new Image()
      //因为是网络资源所以会有图片跨域问题产生，此属性可以解决跨域问题，下文详解
      img.setAttribute('crossOrigin', 'anonymous')
      //如果需要兼容ios，这两个顺序一定不能换，先设置crossOrigin后设置src
      img.src = url
      return new Promise((resolve, reject) => {
        img.onload = () => {
          //canvas基本配置
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          resolve({
            success: true,
            //canvas.toDataURL的方法将图片的绝对路径转换为base64编码
            base64: canvas.toDataURL()
          })
        }
        img.onerror = () => {
          reject({ success: false })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.pictrueTextMsg {
  .el-loading-spinner {
    top: 24% !important;
  }
}
</style>
<style lang="scss" scoped>
.pictrueTextMsg {
  padding: 30px;
  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    .options-item {
      margin-right: 20px;
    }
  }
  .copyTrackingNumber {
    margin-left: 20px;
  }
  .pictrue-text {
    margin: 40px auto;
    display: flex;
    justify-content: center;
  }
  .pictrueTextContent {
    // max-width: 677px;
    width: 100%;
    max-width: 578px;
  }
}
</style>
